import { forwardRef, Inject, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiModule, CaplockCommonModule, TrackingService } from 'common';
import { ApiInterceptor } from './interceptor/api-interceptor';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
// @ts-ignore
import fr from '../../../../i18n/fr.json';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { FileSystemService } from 'src/app/service/file-system.service';
import { FileAccessService } from 'src/app/service/file-access.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResourceService } from 'src/app/service/resource.service';
import {
  SelectPictureService
} from 'src/app/module/app/membership/components/select-picture/select-picture.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { UserService } from 'src/app/service/user.service';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { FactoryStructureService } from 'src/app/service/factory-structure.service';
import { MembershipService } from 'src/app/service/membership.service';
import { NetworkService } from 'src/app/service/network.service';
import { FactoryFolderService } from './service/factory-folder.service';
import { Amplify } from "aws-amplify";
import { PredictionService } from "./service/prediction.service";
import { InstructionExecutionService } from "./service/instruction-execution.service";
import { InstructionService } from "./service/instruction.service";
import { OrganService } from "./service/organ.service";
import { RemoveAccountPage } from "./page/remove-account/remove-account.page";
import { SearchExecutionPage } from "./page/search-execution.page";
import { AuthModule } from "angular-auth-oidc-client";

registerLocaleData(localeFr);

Amplify.configure({
  Auth: {
    region: environment.awsRegion,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    identityPoolId: environment.identityPoolId,
  },
  Storage: {
    AWSS3: {
      bucket: environment.awsPictureBucket,
      region: environment.awsRegion,
    }
  }
});

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

// used to access to the translation offline
class LoadTranslation implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const currentLang = params.translateService.currentLang;
    let t: string;
    switch (currentLang) {
      default:
        t = this.foundTranslation(params.key.split('.'), 0, fr);
    }
    // replace params
    if (params.interpolateParams) {
      for (const key of Object.keys(params.interpolateParams)) {
        const value = params.interpolateParams[key];
        t = t.replace(`{{${key}}}`, value);
      }
    }
    return t;
  }

  foundTranslation(keys: string[], i: number, jsonEl: any): string | undefined {
    const child = jsonEl[keys[i]];
    if (!child) {
      return undefined;
    }
    if (keys.length - 1 === i) {
      return child;
    } else {
      return this.foundTranslation(keys, ++i, child);
    }
  }
}

@NgModule({
  declarations: [AppComponent, RemoveAccountPage, SearchExecutionPage],
  imports: [
    AuthModule.forRoot({
      config: {
        authority: 'https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_AKw55KPmM',
        redirectUrl: 'https://okta.staging.caplock.app/authentication/login',
        clientId: 'v210l8vhufhd5e00ojks4kjuv',
        scope: 'email openid profile',
        responseType: 'code'
      },
    }),
    CaplockCommonModule.forRoot({
      environment: environment.name,
      serverUrl: environment.serverUrl,
      webUrl: environment.webUrl,
      websocketUrl: environment.websocketUrl,
      mobile: false,
      awsPictureBucket: environment.awsPictureBucket,
      awsPublicBucket: environment.awsPublicBucket,
    }),
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    DragDropModule,
    IonicModule.forRoot({animated: environment.withAnimation}),
    AppRoutingModule,
    ApiModule.forRoot({rootUrl: environment.serverUrl}),
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LoadTranslation,
      },
      defaultLanguage: 'fr'
    }),
    ColorPickerModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: 'MembershipService', useClass: MembershipService},
    {provide: 'FactoryStructureService', useClass: FactoryStructureService},
    {provide: 'FileSystemService', useClass: FileSystemService},
    {provide: 'FileAccessService', useClass: FileAccessService},
    {provide: 'ResourceService', useClass: ResourceService},
    {provide: 'SelectPictureService', useClass: SelectPictureService},
    {provide: 'FactoryFolderService', useClass: FactoryFolderService},
    {provide: 'UserService', useClass: UserService},
    {provide: 'NetworkService', useClass: NetworkService},
    {provide: 'PredictionService', useClass: PredictionService},
    {provide: 'InstructionExecutionService', useClass: InstructionExecutionService},
    {provide: 'InstructionService', useClass: InstructionService},
    {provide: 'OrganService', useClass: OrganService},
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    DragDropModule,
    Printer
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document,
              private translateService: TranslateService,
              private trackingService: TrackingService) {
    // init google tag
    this.trackingService.setId(environment.googleTagId);
    // set language
    translateService.use(translateService.defaultLang);
    this.document.documentElement.lang = translateService.currentLang;
  }
}
