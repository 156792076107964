// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main-loader img {
  max-width: 500px;
  padding-top: 100px;
  padding-bottom: 50px;
}
.main-loader ion-spinner {
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AAAF;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,oBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ","sourcesContent":[".main-loader {\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n\n  img {\n    max-width: 500px;\n    padding-top: 100px;\n    padding-bottom: 50px;\n  }\n\n  ion-spinner {\n    width: 50px;\n    height: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
