import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthServiceProvider, UserServiceInterface } from 'common';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate, CanLoad {
  public loader = false;

  constructor(private authProvider: AuthServiceProvider,
              @Inject('UserService') private userService: UserServiceInterface,
              private router: Router) {
  }

  canActivate(_: ActivatedRouteSnapshot, _2: RouterStateSnapshot): Observable<boolean> {
    return of(void 0)
      .pipe(
        tap(() => this.loader = true),
        mergeMap(() => this.authProvider.service.isLogged()),
        map(logged => {
          if (!logged) {
            this.router.navigate(['/authentication/login']);
          }
          return logged;
        }),
        mergeMap((logged) => (logged ? this.userService.refreshUser({forceReload: true}) : of(void 0))
          .pipe(map(() => logged))
        ),
        tap(() => this.loader = false),
      );
  }

  canLoad(_: Route, _2: UrlSegment[]): Observable<boolean> {
    return this.authProvider.service.isLogged()
      .pipe(
        map(logged => {
          if (!logged) {
            this.router.navigate(['/authentication/login']);
          }
          return logged;
        }),
      );
  }

}
