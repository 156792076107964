import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsNotLoggedGuard } from './guard/is-not-logged.guard';
import { IsLoggedGuard } from './guard/is-logged.guard';
import { RemoveAccountPage } from "./page/remove-account/remove-account.page";
import { SearchExecutionPage } from "./page/search-execution.page";
import { environment } from "../environments/environment";

const routes: Routes = [
  {
    path: 'authentication',
    canActivate: [IsNotLoggedGuard],
    loadChildren: async () => {
      const currentDomain = window.location.hostname;
      let authModule = 'generic';
      if (currentDomain != 'localhost') {
        const defaultDomain = environment.webUrl.split('https://')[1];
        let specificDomain = currentDomain.split('.' + defaultDomain);
        if (specificDomain?.length > 1) {
          authModule = specificDomain[0];
        }
      }
      const m = await import('./module/authentication/' + authModule + '/authentication.module');
      return m.AuthenticationPageModule;
    }
  },
  {
    path: 'app',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('./module/app/app.module').then(m => m.AppPageModule)
  },
  {
    path: 'certificate-code',
    component: SearchExecutionPage,
  },
  {
    path: 'more',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('./module/additional-information/additional-information.module').then(m => m.AdditionalInformationModule)
  },
  {
    path: 'remove-account',
    component: RemoveAccountPage,
  },
  {path: '**', redirectTo: 'authentication', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public static buildCertificateCodeRoute(url:string, certificateCode: string): string {
    return `${url}/certificate-code#?code=${certificateCode}`;
  }
}
